.event-namespace {
    display: flex;
    flex-direction: column;
    min-width: 536px;
    max-width: 2000px;
    margin: auto;
    padding: 2em;
}
.namespace-title {
    align-self: flex-start;
    text-transform: uppercase;
    color: #717171;
    font-weight: bold;
    margin-left: 1em;
    margin-bottom: 1em;
}
.group-parent-header {
    display: flex;
    padding: 1.5em 1.5em 1.5em 1em;
    align-items: center;
    border: 1px solid #e0e0e0;
}
.group-parent-header.info {
    border-left: 5px solid #c6e0f7;
}
.group-parent-header.warning {
    border-left: 5px solid #ffac11;
}
.group-parent-header.error {
    border-left: 5px solid red;
}
.share {
    padding: 0 1em;
    visibility: hidden;
}
.group-parent-header:hover {
    background-color: #c6e0f7;
    cursor: pointer;
}
.group-parent-header:hover .share {
    visibility: visible;
}
.group-parent-header:hover .share:hover {
    color: #0084f9;
}
.event-list {
    padding: 1em;
    border-radius: 10px;
    box-shadow: 0 11px 20px 12px rgba(178, 167, 181, 0.37);
    min-width: 680px;
}
.group-collection {
    display: flex;
}
.group-padding {
    width: 1px;
    background-color: #c6e0f7;
    border-top: 3.8em solid white;
    border-bottom: 2em solid white;
    margin-right: 1.3em;
}
.group-display {
    flex:1;
}
.group-detail {
    display: flex;
    flex: 1;
}
.group-expander{
    min-width: 1em;
    max-width: 1em;
    height: 1em;
    border: 1px solid #85c0f4;
    border-radius: 50%;
}
.group-expander.open {
    background-color: #c6e0f7;
}
.group-titled {
    padding: 0 1em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 10em;
}
.titled-title {
    color: gray;
    padding-bottom: .5em;
}
.time-group {
    color: gray;
    display: flex;
    flex-wrap: wrap;
    font-size: .8em;
    padding: 1em;
    flex-direction: column;
}
.time-start, .time-end {
    padding: 0 .5em;
}
.vertical-bar {
    height: 1em;
    margin-left: 1em;
    border-right: 2px solid #e0e0e0;
}

.spark-lines {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    height: 35px;
}
.spark-lines > svg:nth-child(2), .spark-lines > svg:nth-child(3) {
    margin-left: -150px;
    width: 150px;
}

@media (max-width: 1400px){
    .spark-lines, .event-count, .event-component, .event-user {
        display: none;
    }
    .group-titled {
        align-items: unset;
        margin: auto;
        min-width: 5em;
    }
    .group-padding {
        margin-right: .2em;
    }
}
