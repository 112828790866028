
.event-card {
    margin: 0 0 0 1.5em;
    font-size: .9em;
}

.warning .event-card-header {
    border-left: 5px solid #ffac11;
}
.info .event-card-header {
    border-left: 5px solid #c6e0f7;
}
.error .event-card-header {
    border-left: 5px solid red;
}
.event-card-header {
    display: flex;
    flex-wrap: wrap;
    padding: .5em;
    border: 1px solid #e0e0e0;
}
.event-card-header:hover {
    cursor: pointer;
}
.event-card-info {
    display: flex;
    flex: 1;
    padding: 1em 0;
    align-items: center;
}

.event-card-id {
    min-width: 24em;
}
.event-card-timestamp {
    display: flex;
    align-items: center;
    padding: 1em 0;
}

.event-card.error>.event-card-header {
   background-color: #ff000012;
}
.event-card.warning>.event-card-header {
   background-color: #ffa90012;
}

.event-icon {
    margin-right: .5em;
    font-size: 1.2em;
    color: #aeaeae;
}
.error .event-icon {
    color: #ff0000d6;
}
.warning .event-icon {
    color: #ffac0ead;
}

.event-body {
    min-height: 10em;
    padding: 1em;
    border: 1px solid #e0e0e0;
    margin-bottom: 1em;
    border-top: 0;
    text-align: left;
}

@media (max-width: 1000px){
    .event-card-info, .event-card-timestamp, .event-card-header {
        flex-direction: column;
    }
}
