.login-view {
    display: flex;
    flex-direction: column;
    max-width: 30em;
    min-width: 20em;
    align-items: center;
    padding: 1em;
    margin: 1em auto;
}
.form-title {
    margin-bottom: 1em;
}
.form-input {
    padding: .5em;
    border: 1px solid grey;
    border-radius: 5px;
    margin-bottom: 1em;
}
.form-section {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.form-button {
    padding: .5em 2em;
    appearance: none;
    border: 1px solid grey;
    border-radius: 5px;
    background-color: #cae7ff;
    cursor: pointer;
}
